import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const ExistingCustomer = (props) => {
  let params = useParams();

  const [bookingCount, setBookingCount] = useState(0);

  function convertPointToDollar(basePoint, baseAmount, availablePoints) {
    if (availablePoints > 0) {
      return (
        (parseFloat(availablePoints) / parseFloat(basePoint)) *
        parseFloat(baseAmount)
      ).toFixed(2);
    } else {
      return 0;
    }
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  const checkinBtnOnClick = (booking) => {
    const formData = {
      booking_id: booking.id,
      booking_reference: booking.booking_reference,
    };

    props.setBookingCheckinStatus(formData);
  };

  const noBookingCheckIn = () => {
    console.log(bookingCount);
    if (bookingCount <= 0) {
      props.checkInCustomerNoBooking(props.customerResponse.id);
    } else {
      window.location.href = "/final_screen";
    }
  };

  useEffect(() => {
    if (props.reloadCustomer) {
      props.processCustomerPhoneInput(params.phoneNumber);
    }
  }, [props.reloadCustomer]);

  return (
    <div className="appContainer__main__existCustomer">
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h6"
        gutterBottom
        component="div"
      >
        Welcome back, {titleCase(props.customerResponse.first_name)}
      </Typography>
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h6"
        gutterBottom
        component="div"
      >
        You have {props.customerResponse.redeemed_points} points ($
        {convertPointToDollar(
          1000,
          props.store.loyalty_convert_point,
          props.customerResponse.redeemed_points
        )}
        ) available to redeem.
      </Typography>
      {props.customerResponse.bookings.length > 0 ? (
        <div>
          <Typography
            style={{ textAlign: "center" }}
            mt={3}
            variant="h6"
            gutterBottom
            component="div"
          >
            You have {props.customerResponse.bookings.length} booking(s) with us
            today.
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            mb={3}
            variant="subtitle"
            gutterBottom
            component="div"
          >
            Please select booking you want to check-in
          </Typography>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Booking #</TableCell>
                  <TableCell align="right">Service</TableCell>
                  <TableCell align="right">Start</TableCell>
                  <TableCell align="right">End</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.customerResponse.bookings.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.booking_reference}
                    </TableCell>
                    <TableCell align="right">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: row.booking_details
                            .map(
                              (bookingDetailItem) =>
                                bookingDetailItem.service.name
                            )
                            .join("<br>"),
                        }}
                      ></div>
                    </TableCell>
                    <TableCell align="right">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: row.booking_details
                            .map(
                              (bookingDetailItem) =>
                                bookingDetailItem.start_time
                            )
                            .join("<br>"),
                        }}
                      ></div>
                    </TableCell>
                    <TableCell align="right">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: row.booking_details
                            .map(
                              (bookingDetailItem) => bookingDetailItem.end_time
                            )
                            .join("<br>"),
                        }}
                      ></div>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          checkinBtnOnClick(row);
                          setBookingCount(bookingCount + 1);
                        }}
                        endIcon={<CheckCircleOutlineIcon />}
                      >
                        Check-in
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div>
          <Alert severity="success">
            Please click Next Button to complete your check-in session
          </Alert>
        </div>
      )}
      <ButtonGroup
        variant="outlined"
        style={{
          margin: "15px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={(e) => {
            window.location.href = "/";
          }}
          startIcon={<ArrowBackIosNewIcon />}
        >
          Back
        </Button>
        <Button
          onClick={(e) => noBookingCheckIn()}
          endIcon={<NavigateNextIcon />}
        >
          Next
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ExistingCustomer;
