import "./App.css";
import "./custom.css";
import Main from "./components/Main";
import NewCustomer from "./components/NewCustomer";
import ExistingCustomer from "./components/ExistingCustomer";
import FinalScreen from "./components/FinalScreen";
import { useState, useEffect } from "react";
//lib
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const apiURL = "https://tokyonailsandspa.ca/apps/inail/";
  const apiURLNext = "http://inailcheckin-server.tokyonailsandspa.ca/api/";

  const [storeApi, setStoreApi] = useState({});
  const [customerResponse, setCustomerResponse] = useState({});
  const [customerStatusCode, setCustomerStatusCode] = useState(0); //1 : new | 2: return customer
  const [reloadCustomer, setReloadCustomer] = useState(false);

  //fetch store api
  const fetchStoreInfo = async () => {
    const response = await axios.get(apiURL + "api/store_info/info");
    setStoreApi(response.data);
  };

  //add new customer
  const addNewCustomer = async (newCustomer) => {
    await axios
      .post(apiURLNext + "add_new_customer", newCustomer)
      .then((response) => {
        //to do
        window.location.href = "/final_screen";
        console.log(response);
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  //booking checkin
  const setBookingCheckinStatus = async (booking) => {
    await axios
      .post(apiURLNext + "customer_checkin", booking)
      .then((response) => {
        //if success trigger reload customerResponse

        toast.success("The booking was checked-in successfully!", {
          position: "bottom-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setReloadCustomer(true);
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  const checkInCustomerNoBooking = async (customerId) => {
    const response = await axios
      .get(apiURLNext + "customer_no_booking_checkin/customer_id/" + customerId)
      .then((response) => {
        window.location.href = "/final_screen";
      });
  };

  //process phone number input
  const processCustomerPhoneInput = async (phoneNumber) => {
    const response = await axios.get(
      apiURLNext + "check_customer_phone/phone/" + phoneNumber
    );

    const customerResponseData = response.data;
    if (customerResponseData.length === 0) {
      // empty array
      setCustomerStatusCode(1);
      toast.success("Greeting. Let's start you up with your first check-in!", {
        position: "bottom-center",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCustomerResponse(customerResponseData);
      setCustomerStatusCode(2);
      setReloadCustomer(false);
    }
  };

  useEffect(() => {
    fetchStoreInfo();
  }, []);

  return (
    <>
      <div className="appContainer">
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Main
                  processCustomerPhoneInput={processCustomerPhoneInput}
                  customerStatusCode={customerStatusCode}
                  store={storeApi}
                  apiURL={apiURL}
                />
              }
            ></Route>
            <Route
              path={"new_customer/:phoneNumber"}
              element={<NewCustomer addNewCustomer={addNewCustomer} />}
            ></Route>
            <Route
              path={"existing_customer/:phoneNumber"}
              element={
                <ExistingCustomer
                  store={storeApi}
                  reloadCustomer={reloadCustomer}
                  checkInCustomerNoBooking={checkInCustomerNoBooking}
                  processCustomerPhoneInput={processCustomerPhoneInput}
                  setBookingCheckinStatus={setBookingCheckinStatus}
                  customerResponse={customerResponse}
                />
              }
            ></Route>
            <Route path={"final_screen"} element={<FinalScreen />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
